
import {mdiLock} from '@mdi/js'
import MagicWand from '@/components/base/MagicWand'
/*

isLoading
invalid
loadingProgress
usedTokens
maxTokens

*/
export default {
  name: "GenerateButton",
  components: {
    MagicWand
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    loadingProgress: {
      type: Number,
      default: 0
    },
    usedTokens: {
      type: Number,
      default: 0
    },
    maxTokens: {
      type: Number,
      default: 0
    },
    template: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    mdiLock
  }),
  computed: {
    userHasMissingSubscription() {
      return this.isProTemplate && !this.$auth.user?.subscription
    },
    isProTemplate() {
      return !!this.template?.is_pro
    },
    tooltipDisabled() {
      return (this.usedTokens < this.maxTokens) && !this.userHasMissingSubscription
    },
    tooltipText() {
      if (this.userHasMissingSubscription) {
        return this.$t('labels.missing_subscription')
      }
      return this.$t('labels.wordlimit_reached')
    }
  }
}

