
export default {
    name: "MagicWand",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        size: {
            type: [Number, String],
            default: "24",
        },
    },
}
